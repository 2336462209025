exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-ceramics-index-jsx": () => import("./../../../src/pages/ceramics/index.jsx" /* webpackChunkName: "component---src-pages-ceramics-index-jsx" */),
  "component---src-pages-ceramics-sanity-product-categories-slug-current-jsx": () => import("./../../../src/pages/ceramics/{sanityProductCategories.slug__current}.jsx" /* webpackChunkName: "component---src-pages-ceramics-sanity-product-categories-slug-current-jsx" */),
  "component---src-pages-ceramics-shopify-product-product-type-shopify-product-handle-jsx": () => import("./../../../src/pages/ceramics/{ShopifyProduct.productType}/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-ceramics-shopify-product-product-type-shopify-product-handle-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-info-sanity-info-categories-slug-current-jsx": () => import("./../../../src/pages/info/{sanityInfoCategories.slug__current}.jsx" /* webpackChunkName: "component---src-pages-info-sanity-info-categories-slug-current-jsx" */),
  "component---src-pages-sanity-page-slug-current-jsx": () => import("./../../../src/pages/{sanityPage.slug__current}.jsx" /* webpackChunkName: "component---src-pages-sanity-page-slug-current-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */)
}

